<template>
	<div class="max-w-2xl min-w-full mx-auto grid grid-cols-1 gap-6 sm:px-6 py-4 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-6">
		<div class="space-y-6 lg:col-start-1 lg:col-span-2">
			<!-- Description list-->
			<section aria-labelledby="applicant-information-title">
				<div class="bg-white shadow sm:rounded-lg">
					<div class="px-4 pt-4 pb-3 sm:px-6">
						<h2 id="applicant-information-title" class="text-xl leading-6 font-medium text-gray-900">{{ activityStore.activityTemplateSettings?.details_heading ?? ucword(route.params.activity_type.toString()) + ' Details Heading' }}</h2>
						<p class="mt-1 max-w-2xl text-xs text-gray-500">{{ activityStore.activityTemplateSettings?.details_description ?? 'Details Description' }}</p>
					</div>
					<div class="border-t border-gray-200 px-4 pt-3 pb-5 sm:px-6">
						<FormKit type="form" :value="activityStore.activityForms?.initiator ?? {}" :disabled="true" :actions="false">
							<div class="grid grid-cols-12 gap-x-2 gap-y-4 my-4">
								<FormKitSchema :schema="activityStore.activeLayoutSchema" />
							</div>
						</FormKit>
					</div>
				</div>
			</section>
		</div>

		<section aria-labelledby="timeline-title" class="lg:col-start-3 lg:col-span-4">
			<div class="bg-white px-4 pt-4 pb-3 shadow sm:rounded-t-lg sm:px-6 border-b">
				<h2 class="text-xl leading-6 font-medium text-gray-900">{{ ucword(route.params.activity_type.toString()) }} Timeline</h2>
				<p class="mt-1 max-w-2xl text-xs text-gray-500">Ordered by most recent event.</p>
			</div>
			<div class="bg-white px-4 py-4 shadow sm:rounded-b-lg border-t-0 sm:px-6">
				<!-- Activity Feed -->
				<Timeline></Timeline>
			</div>
		</section>
	</div>
</template>

<script lang="ts" setup>
	import Timeline from '@modules/activities/partials/Timeline.vue';
	import { useRoute } from 'vue-router';
	import { ucword } from '@utils/helpers.js';
	import { useActivityStore } from '@modules/activities/store';

	const route = useRoute();
	const activityStore = useActivityStore();
</script>

<style scoped></style>
